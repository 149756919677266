<template>
  <div id="privacy-policy" class="outer-container">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12" style="margin: 10px">
          <div style="width: 100%">
            <h5>
              <span
                class="d-flex flex-column justify-content-center text-center"
              >
                <b>PRIVACY POLICY</b> <br />Last Revised Date: June 30, 2020<br />
              </span>
            </h5>
          </div>
          <div>
            <br />
            <p>
              <b>1. INTRODUCTION</b> Inventory, LLC (“us,” “we,” or “Company”)
              is committed to respecting the privacy rights of its customers,
              visitors, and other users of the Company Website (“Site”). We
              created this Privacy Policy (“Privacy Policy”) to give you
              confidence as you visit and use the Site, and to demonstrate our
              commitment to fair information practices and the protection of
              privacy. This Privacy Policy is only applicable to the Site, and
              not to any other websites that you may be able to access from the
              Site, each of which may have data collection, storage, and use
              practices and policies that differ materially from this Privacy
              Policy.
            </p>
            <br />
            <p><b>2. INFORMATION COLLECTION PRACTICES</b></p>
            <br />
            <p><b>&nbsp;2.1. TYPES OF INFORMATION COLLECTED</b></p>
            <br />
            <p>
              (a) TRAFFIC DATA COLLECTED. We may automatically track and collect
              the following categories of information when you visit our Site:
              (1) IP addresses; (2) domain servers; (3) types of computers
              accessing the Site; (4) types of web browsers used to access the
              Site , and (5) location information and vehicle position
              (collectively “Traffic Data”). Traffic Data is anonymous
              information that does not personally identify you but is helpful
              for marketing purposes or for improving your experience on the
              Site. We also use “cookies” to customize content specific to your
              interests, to ensure that you do not see the same advertisement
              repeatedly, and to store your password so you do not have to
              re-enter it each time you visit the Site.
            </p>
            <br />
            <p>
              (b) PERSONAL INFORMATION COLLECTED. In order for you to access
              certain services and to purchase services or products that we
              offer via the Site, we require you to provide us with certain
              information that personally identifies you (“Personal
              Information”). Personal Information includes the following
              categories of information: (1) Contact Data (such as your name,
              phone number, mailing address, and e-mail address); (2) Financial
              Data (such as your account or credit card number when submitting
              payment information); and (3) Demographic Data (such as your zip
              code and age). If you communicate with us by e-mail or otherwise
              complete online forms, contact customer service or technical
              support, complete surveys, or participate in contest entries,
              submit a resume or application, provide communication preferences,
              and other data, any information provided in such communication may
              be collected as Personal Information.
            </p>
            <br />
            <p>
              <b>2.2. USES OF INFORMATION COLLECTED</b>
            </p>
            <br />
            <p>
              &nbsp;(a) COMPANY USE OF INFORMATION. We may use Contact Data to
              send you information about our company or our products or
              services, or promotional material from some of our partners, or to
              contact you when necessary. We may use your Financial Data to
              verify your qualifications for certain products or services and to
              bill you for products and services. We may use your Demographic
              Data to customize and tailor your experience on the Site,
              displaying content that we think you might be interested in and
              according to your preferences.
            </p>
            <br />
            <p>
              &nbsp;(b) SHARING OF PERSONAL INFORMATION. We share certain
              categories of information we collect from you in the ways
              described in this Privacy Policy. We may share Demographic Data
              with advertisers and other third parties; if required by law, we
              will obtain your prior consent. In such cases, you may any any
              time withdraw your consent or notify us to stop sharing your
              Personal Information via our opt-out in our contact-us form. We
              share Contact Data with other companies who may want to send you
              information about their products or services, unless you have
              specifically requested that we not share Contact Data with such
              companies.
            </p>
            <br />
            <p>
              &nbsp;We also share Contact Data and Financial Data with our
              business partners who assist us by performing core services (such
              as hosting, billing, fulfillment, or data storage and security)
              related to our operation of the Site. Those business partners have
              all agreed to uphold the same standards of security and
              confidentiality that we have promised to you in this Privacy
              Policy, and they will only use your Contact Data and other
              Personal Information to carry out their specific business
              obligations to Company.
            </p>
            <br />
            <p>
              &nbsp;We may share Contact Data in the aggregate or after it is
              anonymized about the user and its use of the services with third
              parties for research, analytics, marketing or other intents, as
              long as such information does not identify a certain individual.
              If you do not want us to share your Contact Data with any third
              parties, please email us using the Contact Us Form here:
              https://fortispay.com/contact-us/, but please understand that such
              a request will likely limit your ability to take advantage of all
              of the features and services we offer on the Site. In addition, we
              maintain a procedure for you to review and request changes to your
              Personal Information; this procedure is described in Section 3.1,
              below.
            </p>
            <br />
            <p>
              &nbsp;(c) USER CHOICE REGARDING COLLECTION, USE, AND DISTRIBUTION
              OF PERSONAL INFORMATION. If you choose not to provide us with any
              Personal Information, you can still access and use the Site;
              however you will not be able to access and use those portions of
              the Site that require your Personal Information. If you do not
              wish to receive information and promotional material from us or
              from some of our partners, you may select the appropriate
              “opt-out” option each time we ask you for Personal Information.
              You may update your selection at any time by contacting our
              Customer Service by using the Contact Us here:
              https://fortispay.com/contact-us/.
            </p>
            <br />
            <p>
              <b>3. CONFIDENTIALITY AND SECURITY OF PERSONAL INFORMATION.</b>
              Except as otherwise provided in this Privacy Policy, we will keep
              your Personal Information private and will not share it with third
              parties, unless such disclosure is necessary to: (a) comply with a
              court order or other legal process; (b) protect our rights or
              property; or (c) enforce our Terms of Service. Your Personal
              Information is stored on secure servers that are not accessible by
              third parties. We provide you with the capability to transmit your
              Personal Information via secured and encrypted channels if you use
              a similarly equipped web browser. Please protect your Personal
              Information including your password or account from others and do
              not share it. We are not responsible for any compromised passwords
              or activity on the account as the aftermath of unauthorized
              password activity. We retain your information only as long as
              required to accomplish the reason for its collection unless
              legally required otherwise.
            </p>
            <br />
            <p>
              &nbsp;<b
                >3.1. USER ABILITY TO ACCESS, UPDATE, AND CORRECT PERSONAL
                INFORMATION.</b
              >
              We maintain a procedure in order to help you confirm that your
              Personal Information remains correct and up-to-date. At any time,
              you may email us to request your personal profile by submitting a
              Contact Us inquiry here: https://fortispay.com/contact-us/.
            </p>
            <br />
            <p>
              &nbsp;<b>3.2. LOST OR STOLEN INFORMATION.</b> You must promptly
              notify us if your credit card, user name, or password is lost,
              stolen, or used without permission. In such an event, we will
              remove that credit card number, user name, or password from your
              account and update our records accordingly.
            </p>
            <br />
            <p>
              &nbsp;<b>3.3. PUBLIC INFORMATION.</b> The Site may contain links
              to other websites. We are not responsible for the privacy
              practices or the content of such websites. We may also make chat
              rooms, forums, message boards, and news groups available to you.
              Please understand that any information that is disclosed in these
              areas becomes public information. We have no control over its use
              and you should exercise caution when deciding to disclose your
              Personal Information.
            </p>
            <br />
            <p>
              <b>4. UPDATES AND CHANGES TO PRIVACY POLICY.</b> We reserve the
              right, at any time and without notice, to add to, change, update,
              or modify this Privacy Policy, simply by posting such change,
              update, or modification on the Site and without any other notice
              to you. Any such change, update, or modification will be effective
              immediately upon posting on the Site.
            </p>
            <br />
            <p>
              <b>5. YOUR CALIFORNIA PRIVACY RIGHTS.</b> Under California’s
              “Shine the Light” law as codified in California Civil Code Section
              1798.83, California residents who provide personal information in
              obtaining products or services for personal, family, or household
              use are entitled to request and obtain from us, once each calendar
              year, information about the customer information we shared, if
              any, with other businesses for their own direct marketing uses. If
              applicable, this information would include the categories of
              customer information and the names and addresses of those
              businesses with which we shared customer information for the
              immediately preceding calendar year. To obtain this information,
              please send an e-mail message via our Contact-Us Page here:
              https://fortispay.com/contact-us/ with “Request for California
              Privacy Information” in the subject line and in the body of your
              message. We will provide in response the requested information to
              you at your e-mail address. Please be aware that not all
              information sharing is covered by the “Shine the Light”
              requirements and only required information will be included in our
              response.
            </p>
            <br />
            <p>
              <b>6. CHILDREN UNDER THE AGE OF 16.</b> Our Site is not intended
              for children under 16 years of age. No one under age 16 may
              provide any personal information to or on the Site. We do not
              knowingly collect personal information from children under 16. If
              you are under 16, do not use or provide any information on this
              Site or through any of its features, register on the Site without
              prior written parental consent, make any purchases through the
              Site, or provide any information about yourself to us, including
              your name, address, telephone number, email address, or any screen
              name or user name you may use. If we learn we have collected or
              received personal information from a child under 16 without
              verification of parental consent, we will delete that information.
              If you believe we might have any information from or about a child
              under 16, please contact us using the Contact Us Page here:
              https://fortispay.com/contact-us/.
            </p>
          </div>
          <div style="width: 100%">
            <span class="d-flex flex-column justify-content-center text-center">
              <h3>
                <b>PRIVACY NOTICE</b> <br />[SUPPLEMENT FOR CALIFORNIA
                RESIDENTS]
              </h3>
            </span>
          </div>
          <div>
            <p>
              <b>Effective Date:</b> June 30, 2020 <br />
              <b>Last Reviewed on:</b> June 30, 2020
            </p>
            <br />
            <p>
              This
              <b>Privacy Notice Supplement for California Residents</b>
              (“Notice”) supplements the information contained in Company’s
              Privacy Notice and applies solely to all visitors, users, and
              others who reside in the State of California (”consumers” or
              “you”). We adopt this notice to comply with the California
              Consumer Privacy Act of 2018 (“CCPA”) and any terms defined in the
              CCPA have the same meaning when used in this Notice. This Notice
              does not apply to employment-related personal information
              collected from California-based employees, job applicants,
              contractors, or similar individuals. Where noted in this Notice,
              the CCPA temporarily exempts personal information reflecting a
              written or verbal business-to-business communication (”<b
                >B2B personal information</b
              >”) from some its requirements.
            </p>
            <br />
            <p>
              <span class="d-flex justify-content-center text-center">
                <u><b>Information We Collect</b></u>
              </span>
            </p>
            <br />
            <p>
              Our Site collects information that identifies, relates to,
              describes, references, is reasonably capable of being associated
              with, or could reasonably be linked, directly or indirectly, with
              a particular consumer, household, or device (”personal
              information”). Personal information does not include:
            </p>
            <ul style="list-style: disc; margin-left: 20px">
              <li style="margin: 10px">
                Publicly available information from government records.
              </li>
              <li style="margin: 10px">
                De-identified or aggregated consumer information.
              </li>
              <li style="margin: 10px">
                Information excluded from the CCPA’s scope, like health or
                medical information covered by the Health Insurance Portability
                and Accountability Act of 1996 (“HIPAA”) and the California
                Confidentiality of Medical Information Act (“CMIA”) or clinical
                trial data; and Personal information covered by certain
                sector-specific privacy laws, including the Fair Credit
                Reporting Act (“FCRA”), the Gramm-Leach-Bliley Act (“GLBA”) or
                California Financial Information Privacy Act (“FIPA”), and the
                Driver’s Privacy Protection Act of 1994.
              </li>
            </ul>
            <p>
              In particular, our Site may have collected the following
              categories of personal information from its consumers within the
              last twelve (12) months:
            </p>
          </div>
          <div>
            <table style="width: 100%" class="tableBorder">
              <tr>
                <th style="border: 1px solid #ccc; margin: 2px">Category</th>
                <th style="border: 1px solid #ccc; margin: 2px">Examples</th>
                <th style="border: 1px solid #ccc; margin: 2px">Collected</th>
              </tr>
              <tr>
                <td style="border: 1px solid #ccc; margin: 2px">
                  A. Identifiers
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">
                  A real name, alias, postal address, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, account name, Social Security number, driver’s
                  license number, passport number, or other similar identifiers.
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">YES</td>
              </tr>
              <tr>
                <td style="border: 1px solid #ccc; margin: 2px">
                  B. Personal information categories listed in the California
                  Customer Records statute (Cal. Civ. Code § 1798.80(e))
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">
                  A name, signature, Social Security number, physical
                  characteristics or description, address, telephone number,
                  passport number, driver’s license or state identification card
                  number, insurance policy number, education, employment,
                  employment history, bank account number, credit card number,
                  debit card number, or any other financial information, medical
                  information, or health insurance information. Some personal
                  information included in this category may overlap with other
                  categories.
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">YES</td>
              </tr>
              <tr>
                <td style="border: 1px solid #ccc; margin: 2px">
                  C. Protected classification characteristics under California
                  or federal law
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">
                  Age (DOB) (40 years or older), race, color, ancestry, national
                  origin, citizenship, religion or creed, marital status,
                  medical condition, physical or mental disability, sex
                  (including gender, gender identity, gender expression,
                  pregnancy or childbirth and related medical conditions),
                  sexual orientation, veteran or military status, genetic
                  information (including familial genetic information).
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">YES</td>
              </tr>
              <tr>
                <td style="border: 1px solid #ccc; margin: 2px">
                  D. Commercial Information
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">
                  Records of personal property, products or services purchased,
                  obtained, or considered, or other purchasing or consuming
                  histories or tendencies.
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">YES</td>
              </tr>
              <tr>
                <td style="border: 1px solid #ccc; margin: 2px">
                  E. Biometric information
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">
                  Genetic, physiological, behavioral, and biological
                  characteristics, or activity patterns used to extract a
                  template or other identifier or identifying information, such
                  as, fingerprints, faceprints, and voiceprints, iris or retina
                  scans, keystroke, gait, or other physical patterns, and sleep,
                  health, or exercise data.
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">NO</td>
              </tr>
              <tr>
                <td style="border: 1px solid #ccc; margin: 2px">
                  F. Internet or other similar network activity.
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">
                  Browsing history, search history, information on a consumer’s
                  interaction with a website, application, or advertisement.
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">YES</td>
              </tr>
              <tr>
                <td style="border: 1px solid #ccc; margin: 2px">
                  G. Geolocation data.
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">
                  Physical location or movements.
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">YES</td>
              </tr>
              <tr>
                <td style="border: 1px solid #ccc; margin: 2px">
                  H. Sensory data.
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">
                  Audio, electronic, visual, thermal, olfactory, or similar
                  information.
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">NO</td>
              </tr>
              <tr>
                <td style="border: 1px solid #ccc; margin: 2px">
                  I. Professional or employment-related information.
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">
                  Current or past job history or performance evaluations.
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">NO</td>
              </tr>
              <tr>
                <td style="border: 1px solid #ccc; margin: 2px">
                  J. Non-public education information (per the Family
                  Educational Rights and Privacy Act (20 U.S.C. Section 1232g,
                  34 C.F.R. Part 99)).
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">
                  Education records directly related to a student maintained by
                  an educational institution or party acting on its behalf, such
                  as grades, transcripts, class lists, student schedules,
                  student identification codes, student financial information,
                  or student disciplinary records.
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">NO</td>
              </tr>
              <tr>
                <td style="border: 1px solid #ccc; margin: 2px">
                  K. Inferences drawn from other personal information.
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">
                  Profile reflecting a person’s preferences, characteristics,
                  psychological trends, predispositions, behavior, attitudes,
                  intelligence, abilities, and aptitudes.
                </td>
                <td style="border: 1px solid #ccc; margin: 2px">YES</td>
              </tr>
            </table>
          </div>
          <br /><br />
          <p>
            Our Site obtains the categories of personal information listed above
            from the following categories of sources:
          </p>
          <ul style="list-style: disc; margin-left: 20px; width: 100%">
            <li style="margin: 10px">
              Directly from you. For example, from forms you complete or
              products and services you purchase.
            </li>
            <li style="margin: 10px">
              Indirectly from you. For example, from third party payment sites
              like PayPal.
            </li>
          </ul>
          <br />
          <p style="width: 100%">
            <span class="d-flex justify-content-center text-center">
              <u><b>Use of Personal Information</b></u>
            </span>
          </p>
          <br />
          <p>
            We may use or disclose the personal information we collect for one
            or more of the following purposes:
          </p>
          <ul style="list-style: disc; margin-left: 20px">
            <li style="margin: 10px">
              To fulfill or meet the reason you provided the information. For
              example, if you share your name and contact information to request
              a price quote or ask a question about our products or services, we
              will use that personal information to respond to your inquiry. If
              you provide your personal information to purchase a product or
              service, we will use that information to process your payment and
              facilitate delivery. We may also save your information to
              facilitate new product orders or process returns.
            </li>
            <li style="margin: 10px">
              To provide, support, personalize, and develop our Site, products,
              and services.
            </li>
            <li style="margin: 10px">
              To create, maintain, customize, and secure your account with us.
            </li>
            <li style="margin: 10px">
              To process your requests, purchases, transactions, and payments
              and prevent transactional fraud.
            </li>
            <li style="margin: 10px">
              To provide you with support and to respond to your inquiries,
              including to investigate and address your concerns and monitor and
              improve our responses.
            </li>
            <li style="margin: 10px">
              To personalize your Site experience and to deliver content and
              product and service offerings relevant to your interests,
              including targeted offers and ads through our Site, third-party
              sites, and via email or text message (with your consent, where
              required by law).
            </li>
            <li style="margin: 10px">
              To help maintain the safety, security, and integrity of our Site,
              products and services, databases and other technology assets, and
              business.
            </li>
            <li style="margin: 10px">
              For testing, research, analysis, and product development,
              including to develop and improve our Site, products, and services.
            </li>
            <li style="margin: 10px">
              To respond to law enforcement requests and as required by
              applicable law, court order, or governmental regulations.
            </li>
            <li style="margin: 10px">
              As described to you when collecting your personal information or
              as otherwise set forth in the CCPA.
            </li>
            <li style="margin: 10px">
              To evaluate or conduct a merger, divestiture, restructuring,
              reorganization, dissolution, or other sale or transfer of some or
              all of our assets, whether as a going concern or as part of
              bankruptcy, liquidation, or similar proceeding, in which personal
              information held by us about our Site users/consumers is among the
              assets transferred.
            </li>
          </ul>
          <p>
            We will not collect additional categories of personal information or
            use the personal information we collected for materially different,
            unrelated, or incompatible purposes without providing you notice.
          </p>
          <br />
          <p style="width: 100%">
            <span class="d-flex justify-content-center text-center">
              <u><b>Sharing Personal Information</b></u>
            </span>
          </p>
          <br />
          <p>
            We may disclose your personal information to a third party for a
            business purpose. When we disclose personal information for a
            business purpose, we enter a contract that describes the purpose and
            requires the recipient to both keep that personal information
            confidential and not use it for any purpose except performing the
            contract. The CCPA prohibits third parties who purchase the personal
            information we hold from reselling it unless you have received
            explicit notice and an opportunity to opt-out of further sales. In
            the preceding twelve (12) months, Company has not disclosed personal
            information for a business purpose. In the preceding twelve (12)
            months, Company has not sold personal information.
          </p>
          <p style="width: 100%">
            <span class="d-flex justify-content-center text-center">
              <u><b>Your Rights and Choices</b></u>
            </span>
          </p>
          <br />
          <p>
            The CCPA provides consumers (California residents) with specific
            rights regarding their personal information. This section describes
            your CCPA rights and explains how to exercise those rights.
          </p>
          <br />
          <p>
            <i>Access to Specific Information and Data Portability Rights</i>
          </p>
          <br />
          <p>
            You have the right to request that we disclose certain information
            to you about our collection and use of your personal information
            over the past 12 months. Once we receive and confirm your verifiable
            consumer request, we will disclose to you:
          </p>
          <ul style="list-style: disc; margin-left: 20px">
            <li style="margin: 10px">
              The categories of personal information we collected about you.
            </li>
            <li style="margin: 10px">
              The categories of sources for the personal information we
              collected about you.
            </li>
            <li style="margin: 10px">
              Our business or commercial purpose for collecting or selling that
              personal information.
            </li>
            <li style="margin: 10px">
              The categories of third parties with whom we share that personal
              information.
            </li>
            <li style="margin: 10px">
              The specific pieces of personal information we collected about you
              (also called a data portability request).
            </li>
            <li style="margin: 10px">
              If we sold or disclosed your personal information for a business
              purpose, two separate lists disclosing:
            </li>
            <li style="margin: 20px">
              sales, identifying the personal information categories that each
              category of recipient purchased; and
            </li>
            <li style="margin: 20px">
              disclosures for a business purpose, identifying the personal
              information categories that each category of recipient obtained.
            </li>
          </ul>
          <br />
          <p style="width: 100%">
            <i><b>Deletion Request Rights</b></i>
          </p>
          <br /><br />
          <p>
            You have the right to request that we delete any of your personal
            information that we collected from you and retained, subject to
            certain exceptions. Once we receive and confirm your verifiable
            consumer request, we will delete (and direct our service providers
            to delete) your personal information from our records, unless an
            exception applies. We may deny your deletion request if retaining
            the information is necessary for us or our service provider(s) to:
          </p>
          <ul style="list-style: disc; margin-left: 20px">
            <li style="margin: 10px">
              Complete the transaction for which we collected the personal
              information, provide a good or service that you requested, take
              actions reasonably anticipated within the context of our ongoing
              business relationship with you, fulfill the terms of a written
              warranty or product recall conducted in accordance with federal
              law, or otherwise perform our contract with you.
            </li>
            <li style="margin: 10px">
              Detect security incidents, protect against malicious, deceptive,
              fraudulent, or illegal activity, or prosecute those responsible
              for such activities.
            </li>
            <li style="margin: 10px">
              Debug products to identify and repair errors that impair existing
              intended functionality.
            </li>
            <li style="margin: 10px">
              Exercise free speech, ensure the right of another consumer to
              exercise their free speech rights, or exercise another right
              provided for by law.
            </li>
            <li style="margin: 10px">
              Comply with the California Electronic Communications Privacy Act
              (Cal. Penal Code § 1546 et. seq.).
            </li>
            <li style="margin: 10px">
              Engage in public or peer-reviewed scientific, historical, or
              statistical research in the public interest that adheres to all
              other applicable ethics and privacy laws, when the information’s
              deletion may likely render impossible or seriously impair the
              research’s achievement, if you previously provided informed
              consent.
            </li>
            <li style="margin: 10px">
              Enable solely internal uses that are reasonably aligned with
              consumer expectations based on your relationship with us.
            </li>
            <li style="margin: 10px">Comply with a legal obligation.</li>
            <li style="margin: 10px">
              Make other internal and lawful uses of that information that are
              compatible with the context in which you provided it.
            </li>
          </ul>
          <br />
          <p style="width: 100%">
            <i
              ><b
                >Exercising Access, Data Portability, and Deletion Rights</b
              ></i
            >
          </p>
          <br />
          <br />
          <p>
            To exercise the access, data portability, and deletion rights
            described above, please submit a verifiable consumer request to us
            by using the Contact Us Form here: <br />
            https://fortispay.com/contact-us/.
          </p>
          <br />
          <p>
            Only you, or someone legally authorized to act on your behalf, may
            make a verifiable consumer request related to your personal
            information. You may also make a verifiable consumer request on
            behalf of your minor child. To designate an authorized agent, use
            the Contact Us Form here: https://fortispay.com/contact-us/. You may
            only make a verifiable consumer request for access or data
            portability twice within a 12-month period. The verifiable consumer
            request must:
          </p>
          <ul style="list-style: disc; margin-left: 20px">
            <li style="margin: 10px">
              Provide sufficient information that allows us to reasonably verify
              you are the person about whom we collected personal information or
              an authorized representative, which may include name and other
              verifiable information.
            </li>
            <li style="margin: 10px">
              Describe your request with sufficient detail that allows us to
              properly understand, evaluate, and respond to it.
            </li>
          </ul>
          <br />
          <p>
            We cannot respond to your request or provide you with personal
            information if we cannot verify your identity or authority to make
            the request and confirm the personal information relates to you.
            Making a verifiable consumer request does not require you to create
            an account with us. We will only use personal information provided
            in a verifiable consumer request to verify the requestor’s identity
            or authority to make the request.
          </p>
          <br />
          <p style="width: 100%">
            <i><b>Response Timing and Format</b></i>
          </p>
          <br /><br />
          <p>
            We endeavor to respond to a verifiable consumer request within
            forty-five (45) days of its receipt. If we require more time (up to
            90 days), we will inform you of the reason and extension period in
            writing. If you have an account with us, we will deliver our written
            response to that account. If you do not have an account with us, we
            will deliver our written response by mail or electronically, at your
            option. Any disclosures we provide will only cover the 12-month
            period preceding the verifiable consumer request’s receipt. The
            response we provide will also explain the reasons we cannot comply
            with a request, if applicable. For data portability requests, we
            will select a format to provide your personal information that is
            readily useable and should allow you to transmit the information
            from one entity to another entity without hindrance. We do not
            charge a fee to process or respond to your verifiable consumer
            request unless it is excessive, repetitive, or manifestly unfounded.
            If we determine that the request warrants a fee, we will tell you
            why we made that decision and provide you with a cost estimate
            before completing your request.
          </p>
          <br />
          <p style="width: 100%">
            <i><b>Personal Information Sales Opt-Out and Opt-In Rights</b></i>
          </p>
          <br /><br />
          <p>
            If you are 16 years of age or older, you have the right to direct us
            to not sell your personal information at any time (the “right to
            opt-out”). We do not sell the personal information of consumers we
            actually know are less than 16 years of age, unless we receive
            affirmative authorization (the “right to opt-in”) from either the
            consumer who is at least 13 but not yet 16 years of age, or the
            parent or guardian of a consumer less than 13 years of age.
            Consumers who opt-in to personal information sales may opt-out of
            future sales at any time. To exercise the right to opt-out, you (or
            your authorized representative) may submit a request to us by
            visiting the Contact Us Form here: https://fortispay.com/contact-us/
            with the title “Do Not Sell My Personal Information”.
          </p>
          <br />
          <p>
            Once you make an opt-out request, we will wait at least twelve (12)
            months before asking you to reauthorize personal information sales.
            However, you may change your mind and opt back in to personal
            information sales at any time using the Contact Us Form here:
            https://fortispay.com/contact-us/.
          </p>
          <br />
          <p>
            You do not need to create an account with us to exercise your
            opt-out rights. We will only use personal information provided in an
            opt-out request to review and comply with the request.
          </p>
          <br />
          <p style="width: 100%">
            <span class="d-flex justify-content-center text-center">
              <b><u>Non-Discrimination</u></b>
            </span>
          </p>
          <br /><br />
          <p>
            We will not discriminate against you for exercising any of your CCPA
            rights. Unless permitted by the CCPA, we will not:
          </p>
          <ul style="list-style: disc; margin-left: 20px">
            <li style="margin: 10px">Deny you goods or services.</li>
            <li style="margin: 10px">
              Charge you different prices or rates for goods or services,
              including through granting discounts or other benefits, or
              imposing penalties.
            </li>
            <li style="margin: 10px">
              Provide you a different level or quality of goods or services.
            </li>
            <li style="margin: 10px">
              Suggest that you may receive a different price or rate for goods
              or services or a different level or quality of goods or services.
            </li>
          </ul>
          <br />
          <p>
            However, we may offer you certain financial incentives permitted by
            the CCPA that can result in different prices, rates, or quality
            levels. Any CCPA-permitted financial incentive we offer will
            reasonably relate to your personal information’s value and contain
            written terms that describe the program’s material aspects.
            Participation in a financial incentive program requires your prior
            opt in consent, which you may revoke at any time.
          </p>
          <br />
          <p style="width: 100%">
            <span class="d-flex justify-content-center text-center">
              <b><u>Other California Privacy Rights</u></b>
            </span>
          </p>
          <br />
          <p>
            California’s “Shine the Light” law (Civil Code Section § 1798.83)
            permits users of our Site that are California residents to request
            certain information regarding our disclosure of personal information
            to third parties for their direct marketing purposes. To make such a
            request, please using the Contact Us Form here:
            https://fortispay.com/contact-us/.
          </p>
          <br />
          <p style="width: 100%">
            <span class="d-flex justify-content-center text-center">
              <b><u>Changes to Our Privacy Notice</u></b>
            </span>
          </p>
          <br />
          <p>
            We reserve the right to amend this privacy notice at our discretion
            and at any time. When we make changes to this privacy notice, we
            will post the updated notice on the Site and update the notice’s
            effective date.
            <b
              >Your continued use of our Site following the posting of changes
              constitutes your acceptance of such changes.</b
            >
          </p>
          <br />
          <p style="width: 100%">
            <span class="d-flex justify-content-center text-center">
              <b><u>Contact Information</u></b>
            </span>
          </p>
          <br />
          <p>
            If you have any questions or comments about this notice, the ways in
            which the Company collects and uses your information described below
            and in the Privacy Policy, your choices and rights regarding such
            use, or wish to exercise your rights under California law, please do
            not hesitate to contact us using the Contact Us Form here: <br />
            https://fortispay.com/contact-us/.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.header-text {
  font-size: 20px;
  font-weight: bold;
  color: #7957d5;
}
.Discount-Button {
  width: 200px;
  margin: 20px 0 0 100px;
}
.align-discount {
  width: 300px;
  margin-left: 100px;
}
.set-btn {
  padding: 0px 17px !important;
  margin: 0px !important;
}
.hr-line {
  padding: 0px;
  margin: 0px;
  width: 60%;
  background-color: #c2c0b7;
  height: 1px;
}
.m-10 {
  margin: 10px;
}
.m-t-10 {
  margin: 10px;
}
.m-b-10 {
  margin: 10px;
}
.m-r-10 {
  margin: 10px;
}
.m-l-10 {
  margin: 10px;
}
.m-l-8 {
  margin-left: 10px;
}
.p-5 {
  padding: 5px;
}
#setting-location-dropdown {
  width: 60%;
  margin-bottom: 10px;
}
.set-return-policy-btn {
  padding: 6px 26px !important;
  margin: 0px !important;
}
.return-policy-col {
  text-align: right;
  padding: 0px 5px !important;
}

.return-policy-eg {
  font-weight: bold !important;
  font-size: 14px !important;
}
.return-policy-eg-text {
  font-weight: normal !important;
  font-size: 14px !important;
}
.p-t-5 {
  padding-top: 5px !important;
}
</style>
