<template>
  <div id="termsandcondition" class="outer-container">
    <div class="container-fluid">
      <div class="row p-1">
        <div class="col-lg-12" style="margin: 10px">
          <div style="width: 100%">
            <h4>
              <span class="d-flex justify-content-center text-center">
                <b
                  >Inventory, LLC (“Company”) GDPR-Compliant Supplemental
                  Privacy Policy <br />Last Revised Date: June 30, 2020</b
                ><br />
              </span>
            </h4>
          </div>
          <div>
            <br />
            <p>
              Company respects your privacy and is committed to protecting your
              personal data. This privacy policy will inform you as to how we
              look after your personal data when you visit our website
              (regardless of where you visit it from) and tell you about your
              privacy rights and how the law protects you. <br /><br />
            </p>
            <b>PURPOSE OF THIS PRIVACY POLICY</b><br /><br />
            <p>
              This privacy policy aims to give you information on how Company
              collects and processes your personal data through your use of this
              website (“Site”), including any data you may provide through this
              Site when you sign up to our newsletter, purchase a product or
              service, or subscribe. <br />
            </p>
            <br />
            <p>
              This Site is not intended for children and we do not knowingly
              collect data relating to children.
            </p>
            <br />
            <p>
              It is important that you read this privacy policy together with
              any other privacy policy or fair processing policy we may provide
              on specific occasions when we are collecting or processing
              personal data about you so that you are fully aware of how and why
              we are using your data. This privacy policy supplements other
              notices and privacy policies and is not intended to override them.
            </p>
            <br />
            <p><b>CONTROLLER </b></p>
            <br />
            <p>
              Inventory, LLC is the controller and responsible for your personal
              data (collectively referred to as ”Company”, “we”, “us” or “our”
              in this privacy policy). We have appointed a data privacy manager
              who is responsible for overseeing questions in relation to this
              privacy policy. If you have any questions about this privacy
              policy, including any requests to exercise your legal rights,
              please contact the data privacy manager using the details set out
              below.
            </p>
            <br />
            <p><b>CONTACT DETAILS </b></p>
            <br />
            <p>
              If you have any questions about this privacy policy or our privacy
              practices, please contact our data privacy manager via our Contact
              Us Form here: https://fortispay.com/contact-us/. You have the
              right to make a complaint at any time. We would, however,
              appreciate the chance to deal with your concerns before you
              approach any authority. Therefore, please contact us in the first
              instance.
            </p>
            <br />
            <p>
              <b
                >CHANGES TO THE PRIVACY POLICY AND YOUR DUTY TO INFORM US OF
                CHANGES</b
              >
            </p>
            <br />
            <p>
              We keep our privacy policy under regular review. This version was
              last updated on the date specified above. Historic versions can be
              obtained by contacting us. It is important that the personal data
              we hold about you is accurate and current. Please keep us informed
              if your personal data changes during your relationship with us.
            </p>
            <br />
            <p><b>THIRD-PARTY LINKS</b></p>
            <br />
            <p>
              This Site may include links to third-party websites, plug-ins and
              applications. Clicking on those links or enabling those
              connections may allow third parties to collect or share data about
              you. We do not control these third-party websites and are not
              responsible for their privacy statements. When you leave our Site,
              we encourage you to read the privacy policy of every website you
              visit.
            </p>
            <br />
            <p><b>2. THE DATA WE COLLECT ABOUT YOU </b></p>
            <br />
            <p>
              Personal data, or personal information, means any information
              about an individual from which that person can be identified. It
              does not include data where the identity has been removed
              (anonymous data). We may collect, use, store and transfer
              different kinds of personal data about you which we have grouped
              together as follows:
            </p>
            <ul style="list-style: disc; margin-left: 30px">
              <li style="margin: 10px">
                <b>Identity Data</b> includes first name, maiden name, last
                name, username or similar identifier, marital status, title,
                date of birth and gender.
              </li>
              <li style="margin: 10px">
                <b>Contact Data</b> includes billing address, delivery address,
                email address and telephone numbers.
              </li>
              <li style="margin: 10px">
                <b>Financial Data</b> includes bank account and payment card
                details.
              </li>
              <li style="margin: 10px">
                <b>Transaction Data</b> includes details about payments to and
                from you and other details of products and services you have
                purchased from us
              </li>
              <li style="margin: 10px">
                <b>Technical Data</b> includes internet protocol (IP) address,
                your login data, browser type and version, time zone setting and
                location, browser plug-in types and versions, operating system
                and platform, and other technology on the devices you use to
                access this Site.
              </li>
              <li style="margin: 10px">
                <b>Profile Data</b> includes your username and password,
                purchases or orders made by you, your interests, preferences,
                feedback and survey responses.
              </li>
              <li style="margin: 10px">
                <b>Usage Data</b> includes information about how you use our
                Site, products and services.
              </li>
              <li style="margin: 10px">
                <b>Marketing and Communications Data</b> includes your
                preferences in receiving marketing from us and our third parties
                and your communication preferences.
              </li>
            </ul>
            <p>
              We also collect, use and share <b>Aggregated Data</b> such as
              statistical or demographic data for any purpose. Aggregated Data
              could be derived from your personal data but is
              <b>not</b> considered personal data in law as this data will not
              directly or indirectly reveal your identity. For example, we may
              aggregate your Usage Data to calculate the percentage of users
              accessing a specific Site feature. However, if we combine or
              connect Aggregated Data with your personal data so that it can
              directly or indirectly identify you, we treat the combined data as
              personal data which will be used in accordance with this privacy
              policy.
            </p>
            <br />
            <p>
              We do not collect any
              <b>Special Categories of Personal Data</b> about you (this
              includes details about your race or ethnicity, religious or
              philosophical beliefs, sex life, sexual orientation, political
              opinions, trade union membership, information about your health,
              and genetic and biometric data). Nor do we collect any information
              about criminal convictions and offences.
            </p>
            <br />
            <p><b>IF YOU FAIL TO PROVIDE PERSONAL DATA</b></p>
            <p>
              Where we need to collect personal data by law, or under the terms
              of a contract we have with you, and you fail to provide that data
              when requested, we may not be able to perform the contract we have
              or are trying to enter into with you (for example, to provide you
              with goods or services). In this case, we may have to cancel a
              product or service you have with us but we will notify you if this
              is the case at the time.
            </p>
            <br />
            <p><b>3. HOW IS YOUR PERSONAL DATA COLLECTED?</b></p>
            <p>
              We use different methods to collect data from and about you
              including through:
            </p>
            <br />
            <ul style="list-style: disc; margin-left: 30px">
              <li style="margin: 10px">
                <b>Direct interactions.</b> You may give us your Identity,
                Contact and Financial Data by filling in forms or by
                corresponding with us by phone, email or otherwise. This
                includes personal data you provide when you subscribe for our
                products or services.
              </li>
              <li style="margin: 10px">
                <b>Automated technologies or interactions.</b> As you interact
                with our Site, we will automatically collect Technical Data
                about your equipment, browsing actions and patterns. We collect
                this personal data by using cookies or other similar
                technologies. We may also receive Technical Data about you if
                you visit other websites employing our cookies. Please see our
                Privacy Policy for further details.
              </li>
              <li style="margin: 10px">
                <b>Third parties or publicly available sources.</b> We may
                receive personal data about you from various third parties and
                public sources as set out below:
              </li>
            </ul>
            Technical Data from the following parties:
            <ul style="list-style: lower-alpha; margin-left: 40px">
              <li style="margin: 10px">
                analytics providers such as Google based outside the EU;
              </li>
              <li style="margin: 10px">advertising networks; and</li>
              <li style="margin: 10px">search information providers</li>
            </ul>
            <br />
            <p><b>4. HOW WE USE YOUR PERSONAL DATA</b></p>
            <br />
            <p>
              We will only use your personal data when the law allows us to.
              Most commonly, we will use your personal data in the following
              circumstances:
            </p>
            <ul style="list-style: disc; margin-left: 20px">
              <li style="margin: 10px">
                Where we need to perform the contract we are about to enter into
                or have entered into with you.
              </li>
              <li style="margin: 10px">
                Where it is necessary for our legitimate interests (or those of
                a third party) and your interests and fundamental rights do not
                override those interests.
              </li>
              <li style="margin: 10px">
                Where we need to comply with a legal obligation.
              </li>
            </ul>
            <p>
              Generally, we do not rely on consent as a legal basis for
              processing your personal data although we will get your consent
              before sending third party direct marketing communications to you
              via email or text message. You have the right to withdraw consent
              to marketing at any time by contacting us.
            </p>
            <br />
            <p><b>PURPOSES FOR WHICH WE WILL USE YOUR PERSONAL DATA</b></p>
            <br />
            <p>
              We have set out below, in a table format, a description of all the
              ways we plan to use your personal data, and which of the legal
              bases we rely on to do so. We have also identified what our
              legitimate interests are where appropriate. Note that we may
              process your personal data for more than one lawful ground
              depending on the specific purpose for which we are using your
              data. Please contact us if you need details about the specific
              legal ground we are relying on to process your personal data where
              more than one ground has been set out in the table below.
            </p>
            <br />
            <div>
              <table style="width: 100%; border: 1px solid #ccc; margin: 2px">
                <tr>
                  <th style="border: 1px solid #ccc; margin: 2px">
                    Purpose/Activity
                  </th>
                  <th style="border: 1px solid #ccc; margin: 2px">
                    Type of data
                  </th>
                  <th style="border: 1px solid #ccc; margin: 2px">
                    Lawful basis for processing including basis of legitimate
                    interest
                  </th>
                </tr>
                <tr>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    To register you as a new customer
                  </td>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    (a) Identity <br />(b) Contact
                  </td>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    Performance of a contract with you
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    To process and deliver your order including: <br />(a)
                    Manage payments, fees and charges <br />(b) Collect and
                    recover money owed to us
                  </td>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    (a) Identity <br />(b) Contact<br />(c) Financial<br />(d)
                    Transaction<br />(e) Marketing and Communications
                  </td>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    (a) Performance of a contract with you <br />(b) Necessary
                    for our legitimate interests (to recover debts due to us)
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    To manage our relationship with you which will include:
                    <br />(a) Notifying you about changes to our terms or
                    privacy policy <br />(b) Asking you to leave a review or
                    take a survey
                  </td>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    (a) Identity<br />(b) Contact<br />(c) Profile<br />(d)
                    Marketing and Communications
                  </td>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    (a) Performance of a contract with you<br />(b) Necessary to
                    comply with a legal obligation<br />(c) Necessary for our
                    legitimate interests (to keep our records updated and to
                    study how customers use our products/services)
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    To enable you to partake in a prize draw, competition or
                    complete a survey
                  </td>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    (a) Identity<br />(b) Contact<br />(c) Profile<br />(d)
                    Usage<br />(e) Marketing and Communications
                  </td>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    (a) Performance of a contract with you<br />(b) Necessary
                    for our legitimate interests (to study how customers use our
                    products/services, to develop them and grow our business)
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    To administer and protect our business and this Site
                    (including troubleshooting, data analysis, testing, system
                    maintenance, support, reporting and hosting of data)
                  </td>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    (a) Identity<br />(b) Contact<br />(c) Technical
                  </td>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    (a) Necessary for our legitimate interests (for running our
                    business, provision of administration and IT services,
                    network security, to prevent fraud and in the context of a
                    business reorganization or group restructuring exercise)<br />(b)
                    Necessary to comply with a legal obligation
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    To deliver relevant Site content and advertisements to you
                    and measure or understand the effectiveness of the
                    advertising we serve to you
                  </td>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    (a) Identity <br />(b) Contact <br />(c) Profile <br />(d)
                    Usage <br />(e) Marketing and Communications <br />(f)
                    Technical
                  </td>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    Necessary for our legitimate interests (to study how
                    customers use our products/services, to develop them, to
                    grow our business and to inform our marketing strategy)
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    To use data analytics to improve our Site,
                    products/services, marketing, customer relationships and
                    experiences
                  </td>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    (a) Technical <br />(b) Usage
                  </td>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    Necessary for our legitimate interests (to define types of
                    customers for our products and services, to keep our Site
                    updated and relevant, to develop our business and to inform
                    our marketing strategy)
                  </td>
                </tr>
                <tr>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    To make suggestions and recommendations to you about goods
                    or services that may be of interest to you
                  </td>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    (a) Identity <br />(b) Contact<br />(c) Technical<br />(d)
                    Usage<br />(e) Profile<br />(f) Marketing and Communications
                  </td>
                  <td style="border: 1px solid #ccc; margin: 2px">
                    Necessary for our legitimate interests (to develop our
                    products/services and grow our business)
                  </td>
                </tr>
              </table>
            </div>
            <br />
            <p><b>MARKETING</b></p>
            <br />
            <p>
              We strive to provide you with choices regarding certain personal
              data uses, particularly around marketing and advertising.
            </p>
            <br />
            <p><b>PROMOTIONAL OFFERS FROM US</b></p>
            <br />
            <p>
              We may use your Identity, Contact, Technical, Usage and Profile
              Data to form a view on what we think you may want or need, or what
              may be of interest to you. This is how we decide which products,
              services and offers may be relevant for you (we call this
              marketing). You will receive marketing communications from us if
              you have requested information from us or purchased goods or
              services from us and you have not opted out of receiving that
              marketing.
            </p>
            <br />
            <p><b>THIRD-PARTY MARKETING</b></p>
            <p>
              We will get your express opt-in consent before we share your
              personal data with any third party for marketing purposes.
            </p>
            <br />
            <p><b>OPTING OUT</b></p>
            <br />
            <p>
              You can ask us or third parties to stop sending you marketing
              messages at any time by contacting us at any time. Where you opt
              out of receiving these marketing messages, this will not apply to
              personal data provided to us as a result of a product/service
              purchase, warranty registration, product/service experience or
              other transactions.
            </p>
            <br />
            <p><b>COOKIES</b></p>
            <br />
            <p>
              You can set your browser to refuse all or some browser cookies, or
              to alert you when websites set or access cookies. If you disable
              or refuse cookies, please note that some parts of this Site may
              become inaccessible or not function properly. For more information
              about the cookies we use, please see our Privacy Policy.
            </p>
            <br />
            <p><b>CHANGE OF PURPOSE</b></p>
            <br />
            <p>
              We will only use your personal data for the purposes for which we
              collected it, unless we reasonably consider that we need to use it
              for another reason and that reason is compatible with the original
              purpose. If you wish to get an explanation as to how the
              processing for the new purpose is compatible with the original
              purpose, please contact us. If we need to use your personal data
              for an unrelated purpose, we will notify you and we will explain
              the legal basis which allows us to do so. Please note that we may
              process your personal data without your knowledge or consent, in
              compliance with the above rules, where this is required or
              permitted by law.
            </p>
            <br />
            <p><b>5. DISCLOSURES OF YOUR PERSONAL DATA</b></p>
            <br />
            <p>
              We may share your personal data with the parties set out below for
              the purposes set out in the table.
            </p>
            <ul style="list-style: disc; margin-left: 20px">
              <li style="margin: 10px">Internal Third Parties.</li>
              <li style="margin: 10px">External Third Parties.</li>
            </ul>
            <p>
              We require all third parties to respect the security of your
              personal data and to treat it in accordance with the law. We do
              not allow our third-party service providers to use your personal
              data for their own purposes and only permit them to process your
              personal data for specified purposes and in accordance with our
              instructions.
            </p>
            <br />
            <p><b>6. INTERNATIONAL TRANSFERS</b></p>
            <br />
            <p>
              We may transfer your personal data outside the European Economic
              Area (“EEA”) for operational purposes. Please contact us if you
              want further information on the specific mechanism used by us when
              transferring your personal data out of the EEA.
            </p>
            <br />
            <p><b>7. DATA SECURITY</b></p>
            <br />
            <p>
              We have put in place appropriate security measures to prevent your
              personal data from being accidentally lost, used or accessed in an
              unauthorized way, altered or disclosed. In addition, we limit
              access to your personal data to those employees, agents,
              contractors and other third parties who have a business need to
              know. They will only process your personal data on our
              instructions and they are subject to a duty of confidentiality. We
              have put in place procedures to deal with suspected personal data
              breach and will notify you and any applicable regulator of a
              breach where we are legally required.
            </p>
            <br />
            <p><b>8. DATA RETENTION</b></p>
            <br />
            <p><b>HOW LONG WILL YOU USE MY PERSONAL DATA FOR? </b></p>
            <br />
            <p>
              We will only retain your personal data for as long as reasonably
              necessary to fulfil the purposes we collected it for, including
              for the purposes of satisfying any legal, regulatory, tax,
              accounting or reporting requirements. We may retain your personal
              data for a longer period in the event of a complaint or if we
              reasonably believe there is a prospect of litigation in respect to
              our relationship with you.
            </p>
            <br />
            <p>
              To determine the appropriate retention period for personal data,
              we consider the amount, nature and sensitivity of the personal
              data, the potential risk of harm from unauthorized use or
              disclosure of your personal data, the purposes for which we
              process your personal data and whether we can achieve those
              purposes through other means, and the applicable legal,
              regulatory, tax, accounting or other requirements.
            </p>
            <br />
            <p>
              By law, we have to keep basic information about our customers
              (including Contact, Identity, Financial and Transaction Data) for
              six years after they cease being customers for tax purposes. In
              some circumstances you can ask us to delete your data: see your
              legal rights below for further information. In some circumstances
              we will anonymize your personal data (so that it can no longer be
              associated with you) for research or statistical purposes, in
              which case we may use this information indefinitely without
              further notice to you.
            </p>
            <br />
            <p><b>9. YOUR LEGAL RIGHTS</b></p>
            <br />
            <p>
              Under certain circumstances, you have rights under data protection
              laws in relation to your personal data. If you wish to exercise
              any of the rights, please contact us.
            </p>
            <br />
            <p><b>NO FEE USUALLY REQUIRED</b></p>
            <br />
            <p>
              You will not have to pay a fee to access your personal data (or to
              exercise any of the other rights). However, we may charge a
              reasonable fee if your request is clearly unfounded, repetitive or
              excessive. Alternatively, we could refuse to comply with your
              request in these circumstances.
            </p>
            <br />
            <p><b>WHAT WE MAY NEED FROM YOU</b></p>
            <br />
            <p>
              We may need to request specific information from you to help us
              confirm your identity and ensure your right to access your
              personal data (or to exercise any of your other rights). This is a
              security measure to ensure that personal data is not disclosed to
              any person who has no right to receive it. We may also contact you
              to ask you for further information in relation to your request to
              speed up our response.
            </p>
            <br />
            <p><b>TIME LIMIT TO RESPOND </b></p>
            <br />
            <p>
              We try to respond to all legitimate requests within one month.
              Occasionally it could take us longer than a month if your request
              is particularly complex or you have made a number of requests. In
              this case, we will notify you and keep you updated.
            </p>
            <br />
            <p><b>10. GLOSSARY</b></p>
            <br />
            <br />
            <p><b>LAWFUL BASIS</b></p>
            <br />
            <p>
              <b>Legitimate Interest</b> means the interest of our business in
              conducting and managing our business to enable us to give you the
              best service/product and the best and most secure experience. We
              make sure we consider and balance any potential impact on you
              (both positive and negative) and your rights before we process
              your personal data for our legitimate interests. We do not use
              your personal data for activities where our interests are
              overridden by the impact on you (unless we have your consent or
              are otherwise required or permitted to by law). You can obtain
              further information about how we assess our legitimate interests
              against any potential impact on you in respect of specific
              activities by contacting us.
            </p>
            <p>
              <b>Performance of Contract</b> means processing your data where it
              is necessary for the performance of a contract to which you are a
              party or to take steps at your request before entering into such a
              contract.
            </p>
            <p>
              <b>Comply with a legal obligation</b> means processing your
              personal data where it is necessary for compliance with a legal
              obligation that we are subject to.
            </p>
            <br />
            <p><b>THIRD PARTIES</b></p>
            <br />
            <br />
            <p><b>INTERNAL THIRD PARTIES</b></p>
            <br />
            <p>
              Other companies who are based in the U.S. and provide IT and
              system administration services.
            </p>
            <br />
            <p><b>EXTERNAL THIRD PARTIES </b></p>
            <br />
            <ul style="list-style: disc; margin-left: 20px">
              <li style="margin: 10px">
                Service providers acting as processors based in the U.S. who
                provide IT and system administration.
              </li>
              <li style="margin: 10px">
                Professional advisers acting as processors or joint controllers
                including lawyers, bankers, auditors and insurers based in the
                U.S. who provide consultancy, banking, legal, insurance and
                accounting.
              </li>
            </ul>
            <br />
            <p><b>YOUR LEGAL RIGHTS</b></p>
            <br />
            <p>You have the right to:</p>
            <p>
              &nbsp;&nbsp;&nbsp;<b>Request access</b> to your personal data
              (commonly known as a “data subject access request”). This enables
              you to receive a copy of the personal data we hold about you and
              to check that we are lawfully processing it.
            </p>
            <br />
            <p>
              &nbsp;&nbsp;&nbsp;<b>Request correction</b> of the personal data
              that we hold about you. This enables you to have any incomplete or
              inaccurate data we hold about you corrected, though we may need to
              verify the accuracy of the new data you provide to us.
            </p>
            <br />
            <p>
              &nbsp;&nbsp;&nbsp;<b>Request erasure</b> of your personal data.
              This enables you to ask us to delete or remove personal data where
              there is no good reason for us continuing to process it. You also
              have the right to ask us to delete or remove your personal data
              where you have successfully exercised your right to object to
              processing (see below), where we may have processed your
              information unlawfully or where we are required to erase your
              personal data to comply with local law. Note, however, that we may
              not always be able to comply with your request of erasure for
              specific legal reasons which will be notified to you, if
              applicable, at the time of your request.
            </p>
            <br />
            <p>
              &nbsp;&nbsp;&nbsp;<b>Object to processing</b> of your personal
              data where we are relying on a legitimate interest (or those of a
              third party) and there is something about your particular
              situation which makes you want to object to processing on this
              ground as you feel it impacts on your fundamental rights and
              freedoms. You also have the right to object where we are
              processing your personal data for direct marketing purposes. In
              some cases, we may demonstrate that we have compelling legitimate
              grounds to process your information which override your rights and
              freedoms.
            </p>
            <br />
            <p>
              &nbsp;&nbsp;&nbsp;Request restriction of processing of your
              personal data. This enables you to ask us to suspend the
              processing of your personal data in the following scenarios:
            </p>
            <ul style="list-style: disc; margin-left: 20px">
              <li style="margin: 10px">
                If you want us to establish the data’s accuracy.
              </li>
              <li style="margin: 10px">
                Where our use of the data is unlawful but you do not want us to
                erase it.
              </li>
              <li style="margin: 10px">
                Where you need us to hold the data even if we no longer require
                it as you need it to establish, exercise or defend legal claims.
              </li>
              <li style="margin: 10px">
                You have objected to our use of your data but we need to verify
                whether we have overriding legitimate grounds to use it.
              </li>
            </ul>
            <br />
            <p>
              <b>Request the transfer</b> of your personal data to you or to a
              third party. We will provide to you, or a third party you have
              chosen, your personal data in a structured, commonly used,
              machine-readable format. Note that this right only applies to
              automated information which you initially provided consent for us
              to use or where we used the information to perform a contract with
              you.
            </p>
            <br />
            <p>
              <b>Withdraw consent at any time</b> where we are relying on
              consent to process your personal data. However, this will not
              affect the lawfulness of any processing carried out before you
              withdraw your consent. If you withdraw your consent, we may not be
              able to provide certain products or services to you. We will
              advise you if this is the case at the time you withdraw your
              consent.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">

.header-text {
  font-size: 20px;
  font-weight: bold;
  color: #7957d5;
}
.Discount-Button {
  width: 200px;
  margin: 20px 0 0 100px;
}
.align-discount {
  width: 300px;
  margin-left: 100px;
}
.set-btn {
  padding: 0px 17px !important;
  margin: 0px !important;
}
.hr-line {
  padding: 0px;
  margin: 0px;
  width: 60%;
  background-color: #c2c0b7;
  height: 1px;
}
.m-10 {
  margin: 10px;
}
.m-t-10 {
  margin: 10px;
}
.m-b-10 {
  margin: 10px;
}
.m-r-10 {
  margin: 10px;
}
.m-l-10 {
  margin: 10px;
}
.m-l-8 {
  margin-left: 10px;
}
.p-5 {
  padding: 5px;
}
#setting-location-dropdown {
  width: 60%;
  margin-bottom: 10px;
}
.set-return-policy-btn {
  padding: 6px 26px !important;
  margin: 0px !important;
}
.return-policy-col {
  text-align: right;
  padding: 0px 5px !important;
}

.return-policy-eg {
  font-weight: bold !important;
  font-size: 14px !important;
}
.return-policy-eg-text {
  font-weight: normal !important;
  font-size: 14px !important;
}
.p-t-5 {
  padding-top: 5px !important;
}
</style>
